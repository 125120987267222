<template>
  <ModalPergunta @clicked-on-save="save()">
    <v-row tag="section" wrap>
      <v-col cols="12" sm="12" md="12">
        <v-row>
          <!-- Botão de sugestão -->
          <v-col class="pb-0 d-flex justify-end" cols="12" sm="12" md="12">
            <SuggestedStructureButtonComponent />
          </v-col>
          <!-- TÍTULO -->
          <v-col class="mt-2" cols="12" sm="12">
            <v-text-field
              class="white"
              hide-details="true"
              ref="form-mot.titulo"
              label="Título"
              placeholder="Escreva o titulo da pergunta"
              required
              outlined
              dense
              v-model="pergunta.titulo"
              data-vv-as="titulo"
              persistent-hint
              :hint="errors.first('form-mot.titulo')"
              :error="errors.collect('form-mot.titulo').length ? true : false"
              v-validate="'required|min:3'"
              data-vv-scope="form-mot"
              data-vv-validate-on="change"
              id="form-mot.titulo"
              name="form-mot.titulo"
            ></v-text-field>
          </v-col>
          <!-- DESCRIÇÃO -->
          <v-col cols="12" sm="12" md="12">
            <v-textarea
              class="white"
              hide-details="true"
              ref="form-mot.descricao"
              label="Descrição"
              outlined
              dense
              v-model="pergunta.descricao"
              data-vv-as="descrição"
              persistent-hint
              :hint="errors.first('form-mot.descricao')"
              :error="
                errors.collect('form-mot.descricao').length ? true : false
              "
              v-validate="'required'"
              data-vv-scope="form-mot"
              data-vv-validate-on="change"
              id="form-mot.descricao"
              name="form-mot.descricao"
            ></v-textarea>
          </v-col>
          <!-- OBRIGATORIEDADE -->
          <v-col cols="12" sm="12" md="6">
            <v-switch
              class="mt-0"
              v-model="pergunta.obrigatoriedade"
              label="Essa pergunta é obrigatória?"
              data-vv-as="obrigatoriedade"
              persistent-hint
              :hint="errors.first('form-mot.obrigatoriedade')"
              :error="
                errors.collect('form-mot.obrigatoriedade').length ? true : false
              "
              v-validate="'required'"
              data-vv-scope="form-mot"
              data-vv-validate-on="change"
              id="form-mot.obrigatoriedade"
              name="form-mot.obrigatoriedade"
            ></v-switch>
          </v-col>
          <!-- LISTA DE OPÇÕES -->
          <v-col cols="12" sm="12" md="12">
            <ListaOpcoesComponent
              :limitOfOptionsOn="true"
              defineScope="form-mot"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </ModalPergunta>
</template>

<script>
import ListaOpcoesComponent from "./components/ListaOpcoesComponent.vue";
import SuggestedStructureButtonComponent from "./components/SuggestedStructureButtonComponent.vue";
import ModalPergunta from "./components/ModalPergunta.vue";

export default {
  name: "FormFIMotivacional",
  inject: ["$validator"],
  components: {
    ListaOpcoesComponent,
    SuggestedStructureButtonComponent,
    ModalPergunta,
  },
  methods: {
    save() {
      this.$validator.validate("form-mot.*").then((result) => {
        if (result) {
          this.pergunta.categoria = "FI";
          this.pergunta.subcategoria = "MOT";
          this.pergunta.tipo = "checkbox";

          if (!this.validateIfExistsOptions()) return;

          this.$store.dispatch("pcoModelos/setClickedOnSave", true);
          this.loadings.loadingModalPergunta = true;
        }
      });
    },
    /**
     * @description Valida de a pergunta é do tipo Radio ou checkbox e se contem opções, caso não tenha será retornado uma mensagem.
     *
     * @return this
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    validateIfExistsOptions() {
      if (
        (this.pergunta.tipo == "radio" &&
          this.pergunta.subcategoria !== "FAV") ||
        this.pergunta.tipo == "checkbox"
      ) {
        if (this.pergunta.opcoes.length > 0) {
          return true;
        } else {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Necessário ter opções registradas.",
            cor: "red",
          });

          return false;
        }
      }

      return true;
    },
  },
  computed: {
    pergunta: {
      get() {
        return this.$store.getters["pcoPerguntas/getPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoPerguntas/setPergunta", value);
      },
    },
    dialogModalPergunta: {
      get() {
        return this.$store.getters["pcoModelos/getDialogModalPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setDialogModalPergunta", value);
      },
    },
    loadings: {
      get() {
        return this.$store.getters["pcoModelos/getLoadings"];
      },
    },
  },
};
</script>

<style scoped>
.flex-salvar-e-fechar {
  display: flex;
  justify-content: flex-end;
  margin-right: 172px;
}
</style>
